import { useAppProvider } from '../../../shared/contexts/app-context';
import {
  useStartClaimMutation,
  useCompleteClaimMutation,
} from '../../../app/store';

import { EarnIcon } from '../../../shared/icons/EarnIcon';

import {
  defaultAnimState,
  getAnimStyle,
  randomInt,
  rnd,
  getTimeLabel,
  getPercent,
} from './utils';
import { EarnBlock } from './EarnBlock';

import './EarnView.css';

let handleTap = null;

const handlePointerUp = (e) => {
  const target = e.currentTarget;
  const parent = target.parentNode;
  const o = parent.getBoundingClientRect();
  const r = { ...defaultAnimState };
  r.scale = 0.9;
  target.style.transform = getAnimStyle(r);
  setTimeout(() => {
    target.style.transform = getAnimStyle(defaultAnimState);
  }, 150);
  const topInitial = e.clientY - o.top + randomInt(-15, 15);
  const leftInitial = e.clientX - o.left + randomInt(-15, 15);
  const div = document.createElement('div');
  div.className = 'earn-view__tap-value';
  div.style.top = `${topInitial}px`;
  div.style.left = `${leftInitial}px`;
  div.style.transform = `scale(${rnd(0.9, 1.3)}) translateZ(0px) rotate(${rnd(
    -15,
    15
  )}deg)`;
  div.innerHTML = '+1';
  parent.appendChild(div);
  setTimeout(() => {
    div.style.top = `${topInitial - randomInt(100, 150)}px`;
    div.style.left = `${leftInitial - randomInt(-30, 30)}px`;
    div.style.transform = `scale(${rnd(0.9, 1.3)}) translateZ(0px) rotate(${rnd(
      -15,
      15
    )}deg)`;
    div.style.opacity = 0;
  }, 50);
  setTimeout(() => {
    div.remove();
  }, 3100);
  if (handleTap) {
    handleTap();
  }
};

export const EarnView = () => {
  const {
    balancePointsTotal,
    farmedPoints,
    pointsTapped,
    claimDuration,
    claimDurationLeft,
    isFarmingCompleted,
    isFarmingStarted,
    energy,
    squad,
    joinedSquad,
    onTap,
  } = useAppProvider();
  const [startClaim] = useStartClaimMutation();
  const [completeClaim, { isLoading }] = useCompleteClaimMutation();

  handleTap = onTap;

  const isTapDisabled = energy.current <= 0;

  return (
    <section className="earn-view">
      <div className="earn-view__body">
        <EarnBlock
          title="Earned"
          variant="orange"
          amount={balancePointsTotal + pointsTapped}
        />

        <EarnBlock
          title="Farming"
          variant="green"
          amount={farmedPoints}
          squadFactor={squad || joinedSquad ? '1.25' : null}
        />

        <div className="earn-view__energy">
          <span className="earn-view__energy-hidden">
            {energy.max}/{energy.max}
          </span>
          <span className="earn-view__energy-visible">
            {energy.current}/{energy.max}
          </span>
          <span className="earn-view__energy-sign">⚡️</span>
        </div>

        <div className="earn-view__intro">Every click turns into treasure</div>
      </div>

      <div className="earn-view__tap-outer">
        <div
          className={`earn-view__tap-box ${
            isTapDisabled ? 'earn-view__tap-box--disabled' : ''
          }`}
          onPointerUp={isTapDisabled ? undefined : handlePointerUp}
        >
          <EarnIcon />
        </div>
      </div>

      <div
        className={`earn-view__claim ${
          (isFarmingStarted && !isFarmingCompleted) || isLoading
            ? ''
            : 'earn-view__claim--active'
        }`}
        onClick={() => {
          if (!isFarmingStarted) {
            startClaim();
          } else if (isFarmingCompleted && !isLoading) {
            completeClaim();
          }
        }}
      >
        <div className="earn-view__claim-inner">
          {getTimeLabel(claimDuration, claimDurationLeft)}
        </div>

        {isFarmingStarted && (
          <div
            className="earn-view__claim-stripe"
            style={{
              width: `${getPercent(claimDuration, claimDurationLeft)}%`,
            }}
          />
        )}
      </div>
    </section>
  );
};
