import { GoldDrillIcon } from '../../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../../shared/ui/Button';

import s from './TourStepClaim.module.css';

export const TourStepClaim = ({ onNext }) => {
  return (
    <section className={s.outer}>
      <div className={s.inner}>
        <div className={s.info}>
          <div className={s.infoHeading}>PRESS TO GET</div>
          <div className={s.infoAmount}>
            40 000 <GoldDrillIcon />
          </div>
        </div>
        <div className={s.button}>
          <Button className={s.btn} onClick={onNext}>
            Claim
          </Button>
        </div>
      </div>
    </section>
  );
};
