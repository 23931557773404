import { ROUTES } from '../../../../shared/contexts/router-context';
import { TabBar } from '../../../../widgets/ui/TabBar';
import { ArrowRighticon } from '../../ui/ArrowRighticon';

import s from './TourStepStakeNavigate.module.css';

export const TourStepStakeNavigate = ({ onNext }) => {
  return (
    <section className={s.outer}>
      <div className={s.inner}>
        <div className={s.intro}>
          <div className={s.text}>come here</div>
          <ArrowRighticon />
        </div>

        <TabBar
          disabledRoutes={[
            ROUTES.EARN,
            ROUTES.QUESTS_ACTIVE,
            ROUTES.REFERAL,
            ROUTES.LEADERBOARD_SQUADS,
          ]}
          onRouteChange={onNext}
        />
      </div>
    </section>
  );
};
