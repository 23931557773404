import { useState, useEffect } from 'react';

import { Button } from '../../../../../shared/ui/Button';
import { ExitIcon } from '../../../../../shared/icons/ExitIcon';
import { useDiggyGameProvider } from '../../../../../shared/contexts/diggy-game-context';
import { GoldDrillIcon } from '../../../../../shared/icons/GoldDrillIcon';
import { formatScore } from '../../../../../shared/helpers/formatters';

import { useDiggyGameDigUpMutation } from '../../../../../app/store';

import { textures } from '../../lib/resources';
import { renderPreviewTextureImages } from '../../lib/helpers';

import { CellPosDelimiterIcon } from './CellPosDelimiterIcon';

import s from './YourStakesModal.module.css';

export const YourStakesModal = ({ visible, onClose, onFinish }) => {
  const { map, digLevels, stakes } = useDiggyGameProvider();
  const [diggyGameDigUp, { isLoading }] = useDiggyGameDigUpMutation();

  const [selectedStakeId, setSelectedStakeId] = useState(null);

  const selectedStake = selectedStakeId
    ? stakes.find((s) => s.id === selectedStakeId) || null
    : null;
  const selectedLevel = selectedStake
    ? digLevels.find((l) => l.level === selectedStake.level) || null
    : null;

  const handleFinish = (res, error) => {
    onFinish({
      error: error || null,
      profit: res?.profit,
      amount: res?.amount,
      stake: selectedStake,
      level: selectedLevel,
    });
  };

  useEffect(() => {
    if (!selectedStake && stakes.length) {
      setSelectedStakeId(stakes[0].id);
    }
  }, [selectedStake, stakes]);

  useEffect(() => {
    if (!stakes.length) {
      onClose();
    }
  }, [stakes, onClose]);

  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.head}>
          <div className={s.close} onClick={onClose}>
            <ExitIcon />
          </div>
        </div>

        <div className={s.body}>
          <div className={s.heading}>YOUR STAKES</div>

          <div className={s.stakes}>
            {stakes.map((stake) => (
              <div
                className={`${s.stake} ${
                  stake === selectedStake ? s.stakeActive : ''
                }`}
                key={`${stake.pos_x}:${stake.pos_y}`}
                onClick={() => setSelectedStakeId(stake.id)}
              >
                {renderPreviewTextureImages(
                  (map[stake.pos_y]?.[stake.pos_x] || []).map(
                    (c) => textures[c]
                  )
                )}
                <div className={s.stakeCell}>
                  <div className={s.stakeCellNum}>{stake.pos_x + 1}</div>
                  <div className={s.stakeCellDelimiter}>
                    <CellPosDelimiterIcon />
                  </div>
                  <div className={s.stakeCellNum}>{stake.pos_y + 1}</div>
                </div>
              </div>
            ))}
          </div>

          <div className={s.stakeInfo}>
            <div className={s.stakeInfoHeading}>Staked coins</div>
            <div className={s.stakeInfoCoins}>
              {formatScore(selectedStake?.amount ?? 0)} <GoldDrillIcon />
            </div>
            <div className={s.stakeInfoHeading}>Parametrs</div>
            <div className={s.stakeInfoParameters}>
              <div className={s.stakeInfoParametersInfo}>
                {`${selectedLevel?.percent_per_hour || 0}% per h / ${
                  selectedLevel?.percent_per_day || 0
                }% per 24h`}
              </div>
              <div className={s.stakeInfoParametersEnergy}>
                {`${selectedLevel?.energy ?? 0}⚡️`}
              </div>
            </div>
            <div className={s.stakeInfoHeading}>Current profit</div>
            <div className={s.stakeInfoCoins}>
              {formatScore(selectedStake?.profit || 0)} <GoldDrillIcon />
            </div>
          </div>

          <Button
            className={s.btn}
            disabled={isLoading || !selectedStake}
            onClick={() => {
              diggyGameDigUp(selectedStake.id)
                .unwrap()
                .then((res) => handleFinish(res))
                .catch((e) => handleFinish(null, e));
            }}
          >
            DIG
          </Button>
        </div>
      </div>
    </section>
  );
};
