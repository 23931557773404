import { useDiggyGameProvider } from '../../../../../shared/contexts/diggy-game-context';

import { SeparatorIcon } from './SeparatorIcon';

import s from './CellPlaceInfo.module.css';

export const CellPlaceInfo = ({
  classNames = {},
  selectText = 'Select a staking place',
  confirmText = 'your choice',
}) => {
  const { selectedCell } = useDiggyGameProvider();

  const headingClass = `${s.heading} ${classNames.heading || ''}`;
  const posCoordClass = `${s.posCoord} ${classNames.posCoord || ''}`;

  return (
    <div className={s.outer}>
      {selectedCell ? (
        <>
          <div className={headingClass}>{confirmText}</div>

          <div className={s.pos}>
            <div className={posCoordClass}>{selectedCell.x + 1}</div>
            <div className={`${s.posDelim} ${classNames.posDelim || ''}`}>
              <SeparatorIcon />
            </div>
            <div className={posCoordClass}>{selectedCell.y + 1}</div>
          </div>
        </>
      ) : (
        <div className={headingClass}>{selectText}</div>
      )}
    </div>
  );
};
