import { useEffect, useState } from 'react';

import { useAppProvider } from '../../../../../shared/contexts/app-context';
import { useDiggyGameProvider } from '../../../../../shared/contexts/diggy-game-context';
import { formatScore } from '../../../../../shared/helpers/formatters';
import { GoldDrillIcon } from '../../../../../shared/icons/GoldDrillIcon';
import { GoldDrillUpIcon } from '../../../../../shared/icons/GoldDrillUpIcon';
import { GoldDrillDownIcon } from '../../../../../shared/icons/GoldDrillDownIcon';
import { CheckBoxIcon } from '../../../../../shared/icons/CheckBoxIcon';
import { ExitIcon } from '../../../../../shared/icons/ExitIcon';
import { Button } from '../../../../../shared/ui/Button';

import { useDiggyGameMakeStakeMutation } from '../../../../../app/store';

import { renderPreviewTextureImages } from '../../lib/helpers';

import { CellPosDelimiterIcon } from './CellPosDelimiterIcon';

import s from './ToStakeModal.module.css';

export const ToStakeModal = ({ visible, cell, onClose, onComplete }) => {
  const { balancePointsTotal } = useAppProvider();
  const { digLevels, energy } = useDiggyGameProvider();
  const [diggyGameMakeStake] = useDiggyGameMakeStakeMutation();

  const [selectedLevel, setSelectedLevel] = useState(1);
  const [amountToStake, setAmountToStake] = useState('');
  const [isStakeMode, setIsStakeMode] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleComplete = (res, error) => {
    setIsProcessing(false);
    onComplete({
      error: error || null,
      digUpAmount: res?.dig_up_amount ?? null,
      stakedAmount: res?.staked_amount ?? null,
      amountToStake,
      stakeId: res?.stake_id ?? null,
      isStakeMode,
      level: digLevels.find((l) => l.level === selectedLevel),
    });
  };

  useEffect(() => {
    if (visible) {
      setSelectedLevel(1);
      setAmountToStake('');
    }
  }, [visible]);

  return (
    <section className={`${s.outer} ${visible && cell ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.head}>
          <div className={s.close} onClick={onClose}>
            <ExitIcon />
          </div>
        </div>

        <div className={s.body}>
          <div className={s.heading}>TO STAKE</div>

          <div className={s.cell}>
            <div className={s.cellPic}>
              {renderPreviewTextureImages(cell?.cell || [])}
            </div>

            <div className={s.cellPosOuter}>
              <div className={s.cellPos}>
                <div className={s.cellPosItem}>{(cell?.x ?? 0) + 1}</div>
                <div className={s.cellPosDelimiter}>
                  <CellPosDelimiterIcon />
                </div>
                <div className={s.cellPosItem}>{(cell?.y ?? 0) + 1}</div>
              </div>

              <div className={s.cellPosAvailable}>
                <div className={s.cellPosAvailableHeading}>Available coins</div>
                <div className={s.cellPosAvailableAmount}>
                  {formatScore(balancePointsTotal)} <GoldDrillIcon />
                </div>
              </div>
            </div>
          </div>

          <div className={s.cellInfo}>
            <div className={s.actionHeading}>What would you prefer?</div>

            <div
              className={`${s.actionBlock} ${
                isStakeMode ? '' : s.actionBlockActive
              }`}
            >
              <div className={s.actionBlockInner}>
                <div className={s.actionBlockIcon}>
                  <GoldDrillUpIcon />
                </div>
                <div className={s.actionBlockText}>Rob this stake</div>
                <div
                  className={s.actionBlockCheck}
                  onClick={() => setIsStakeMode(false)}
                >
                  <CheckBoxIcon />
                </div>
              </div>
            </div>

            <div
              className={`${s.actionBlock} ${
                isStakeMode ? s.actionBlockActive : ''
              }`}
            >
              <div className={s.actionBlockLabel}>Amount to stake</div>
              <div className={s.actionBlockInner}>
                <div className={s.actionBlockIcon}>
                  <GoldDrillDownIcon />
                </div>
                <input
                  value={amountToStake}
                  type="number"
                  placeholder="0"
                  min="0"
                  max={balancePointsTotal}
                  disabled={!isStakeMode}
                  className={s.actionBlockInput}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.target.blur();
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value.trim();
                    if (value.length) {
                      value = parseInt(e.target.value) || 0;
                    }
                    setAmountToStake(
                      value === '' ? '' : Math.min(value, balancePointsTotal)
                    );
                  }}
                />
                <div
                  className={s.actionBlockCheck}
                  onClick={() => setIsStakeMode(true)}
                >
                  <CheckBoxIcon />
                </div>
              </div>
            </div>

            <div
              className={`${s.cellTable} ${
                isStakeMode ? '' : s.cellTableDisabled
              }`}
            >
              <div className={s.cellTableHeading}>
                <div>LVL</div>
                <div>PROFIT</div>
                <div>COST</div>
              </div>

              {digLevels.map((level) => (
                <div
                  className={`${s.cellTableLevel} ${
                    level.level === selectedLevel ? s.cellTableLevelActive : ''
                  } ${level.energy > energy ? s.cellTableLevelDisabled : ''}`}
                  key={level.level}
                  onClick={() => {
                    setSelectedLevel(level.level);
                  }}
                >
                  <div className={s.cellTableLevelCellLevel}>{level.level}</div>
                  <div className={s.cellTableLevelCellProfit}>
                    {`${level.percent_per_hour}% per h / ${level.percent_per_day}% per 24h`}
                  </div>
                  <div className={s.cellTableLevelCellCost}>
                    {`${level.energy}⚡️`}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Button
            variant="green"
            disabled={
              !energy || (isStakeMode && !amountToStake) || isProcessing
            }
            className={s.btn}
            onClick={() => {
              if ((isStakeMode && !amountToStake) || isProcessing) {
                return;
              }
              setIsProcessing(true);
              diggyGameMakeStake({
                pos_x: cell.x,
                pos_y: cell.y,
                amount: isStakeMode ? amountToStake : 0,
                level: selectedLevel,
              })
                .unwrap()
                .then((res) => handleComplete(res))
                .catch((error) => handleComplete(null, error));
            }}
          >
            DIG
          </Button>
        </div>
      </div>
    </section>
  );
};
