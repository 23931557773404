import { useDiggyGameProvider } from '../../../../shared/contexts/diggy-game-context';
import { Button } from '../../../../shared/ui/Button';
import { CellPlaceInfo } from '../../../games/DiggyGame/ui/CellPlaceInfo';

import s from './TourStepStakeChoosePlace.module.css';

export const TourStepStakeChoosePlace = () => {
  const { selectedCell } = useDiggyGameProvider();

  return (
    <>
      <section className={s.outerTop}></section>

      <section className={s.outerRight}></section>

      <section className={s.outerLeft}></section>

      <section className={s.outerBottom}>
        <CellPlaceInfo
          selectText="choose the place"
          confirmText="confirm your choise"
          classNames={{
            heading: s.placeHeading,
            posCoord: s.placePosCoord,
            posDelim: s.placePosDelim,
          }}
        />
        <div className={`${s.button} ${selectedCell ? s.active : ''}`}>
          <Button variant="green">Select</Button>
        </div>
      </section>

      <section className={s.outerCenter}></section>
    </>
  );
};
