import {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';

import { useAppProvider } from '../../../shared/contexts/app-context';
import { useDiggyGameProvider } from '../../../shared/contexts/diggy-game-context';
import { formatScore } from '../../../shared/helpers/formatters';
import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../shared/ui/Button';

import { startGame, EventBus } from './lib/helpers';
import { tileTypes } from './lib/resources';
import { regenerateSceneMatrix } from './lib/scenes/WorldScene';

import { IntroModal } from './ui/IntroModal';
import { FoundTreasureModal } from './ui/FoundTreasureModal';
import { StolenTreasureModal } from './ui/StolenTreasureModal';
import { ToStakeModal } from './ui/ToStakeModal';
import { YourStakesModal } from './ui/YourStakesModal';
import { StakeEmptyModal } from './ui/StakeEmptyModal';
import { StakedCoinsModal } from './ui/StakedCoinsModal';
import { FoundAndStakedCoinsModal } from './ui/FoundAndStakedCoinsModal';
import { CellPlaceInfo } from './ui/CellPlaceInfo';

import s from './DiggyGame.module.css';

const containerId = 'diggy-game-container';

const getContainerSizes = (node) => ({
  width: node.offsetWidth,
  height: node.offsetHeight,
});

export const DiggyGame = forwardRef(({ currentActiveScene }, ref) => {
  const { balancePointsTotal } = useAppProvider();
  const {
    map,
    energy,
    energyMax,
    stakes,
    forceReloadStatus,
    stakesStolen,
    selectedCell,
    setSelectedCell,
  } = useDiggyGameProvider();

  const game = useRef(null);
  const currentScene = useRef(null);
  const containerRef = useRef();

  const [introVisible, setIntroVisible] = useState(false);
  const [foundTreasureVisible, setFoundTreasureVisible] = useState(false);
  const [stolenTreasureVisible, setStolenTreasureVisible] = useState(false);
  const [toStakeVisible, setToStakeVisible] = useState(false);
  const [yourStakesVisible, setYourStakesVisible] = useState(false);
  const [stakeEmptyVisible, setStakeEmptyVisible] = useState(false);
  const [stakedCoinsVisible, setStakedCoinsVisible] = useState(false);
  const [foundAndStakedCoinsVisible, setFoundAndStakedCoinsVisible] =
    useState(false);
  const [lastDigUpAmount, setLastDigUpAmount] = useState(null);
  const [stakedCoinsAmount, setStakedCoinsAmount] = useState(null);
  const [stakedCoinsLevel, setStakedCoinsLevel] = useState(null);
  const [stolenStakes, setStolenStakes] = useState(null);

  const canDigOnCell =
    selectedCell && selectedCell.rootCell.type === tileTypes.SAND;

  useImperativeHandle(
    ref,
    () => ({
      game: game.current,
      scene: currentScene.current,
    }),
    []
  );

  useEffect(() => {
    const sizes = getContainerSizes(containerRef.current);

    if (!game.current) {
      game.current = startGame({ parent: containerId, ...sizes });
    }

    EventBus.on('current-scene-ready', (currentScene) => {
      if (currentActiveScene instanceof Function) {
        currentActiveScene(currentScene);
      }
      currentScene.current = currentScene;
    });

    EventBus.on('sprite-click', (scene) => {
      setSelectedCell(scene.selected ? scene : null);
    });

    const resizeHandler = () => {
      if (game.current) {
        const sizes = getContainerSizes(containerRef.current);

        game.current.scale.resize(sizes.width, sizes.height);
        game.current.scene.getScenes(true).forEach((scene) => {
          scene.cameras.main.setSize(sizes.width, sizes.height);
        });
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      if (game.current) {
        game.current.destroy(true);
        game.current = null;
        currentScene.current = null;
      }

      EventBus.removeListener('current-scene-ready');
      EventBus.removeListener('sprite-click');

      window.removeEventListener('resize', resizeHandler);
    };
  }, [currentActiveScene, ref, setSelectedCell]);

  useEffect(() => {
    regenerateSceneMatrix(map);
    if (game.current) {
      game.current.scene.getScenes(true).forEach((scene) => {
        if (scene.scene.key === 'WorldScene') {
          scene.scene.restart();
        }
      });
    }
  }, [map]);

  useEffect(() => {
    const key = 'diggy-game-intro-showed';
    if (localStorage.getItem(key) !== 'true') {
      const timer = setTimeout(() => {
        setIntroVisible(true);
        localStorage.setItem(key, 'true');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (stakesStolen) {
      setStolenStakes(stakesStolen);
      setStolenTreasureVisible(true);
    }
  }, [stakesStolen]);

  useEffect(() => {
    const visible =
      introVisible ||
      foundTreasureVisible ||
      stolenTreasureVisible ||
      toStakeVisible ||
      yourStakesVisible ||
      stakeEmptyVisible ||
      stakedCoinsVisible ||
      foundAndStakedCoinsVisible;

    const timer = setTimeout(
      () => {
        EventBus.emit('modal-visibility-change', {
          visible,
        });
      },
      visible ? 0 : 300
    );

    forceReloadStatus(yourStakesVisible);

    return () => {
      clearTimeout(timer);
    };
  }, [
    introVisible,
    foundTreasureVisible,
    stolenTreasureVisible,
    toStakeVisible,
    yourStakesVisible,
    stakeEmptyVisible,
    stakedCoinsVisible,
    foundAndStakedCoinsVisible,
    forceReloadStatus,
  ]);

  return (
    <section className={s.outer}>
      <div className={`${s.scroller} scroll-area`}>
        <div className={s.counters}>
          <div className={s.counter}>
            {formatScore(balancePointsTotal)} <GoldDrillIcon />
          </div>

          <div className={s.counter}>
            {energy}/{energyMax}⚡
          </div>
        </div>

        <div className={s.heading}>Select a staking place</div>

        <div className={s.inner}>
          <div className={s.container} id={containerId} ref={containerRef} />
        </div>

        <CellPlaceInfo />

        <div className={s.buttons}>
          <Button
            variant="green"
            className={s.buttonsBtn}
            disabled={!canDigOnCell}
            onClick={() => {
              if (canDigOnCell) {
                setToStakeVisible(true);
              }
            }}
          >
            Select
          </Button>

          <Button
            className={s.buttonsBtn}
            disabled={!stakes.length}
            onClick={() => setYourStakesVisible(true)}
          >
            Your stakes
          </Button>
        </div>
      </div>

      <IntroModal
        visible={introVisible}
        onClose={() => setIntroVisible(false)}
      />

      <FoundTreasureModal
        visible={foundTreasureVisible}
        amount={lastDigUpAmount}
        onClose={() => setFoundTreasureVisible(false)}
      />

      <StolenTreasureModal
        visible={stolenTreasureVisible}
        stakes={stolenStakes}
        onClose={() => setStolenTreasureVisible(false)}
      />

      <ToStakeModal
        visible={toStakeVisible}
        cell={selectedCell}
        onClose={() => setToStakeVisible(false)}
        onComplete={({ digUpAmount, stakedAmount, level }) => {
          setToStakeVisible(false);
          if (stakedAmount && digUpAmount) {
            setStakedCoinsAmount(stakedAmount);
            setLastDigUpAmount(digUpAmount);
            setStakedCoinsLevel(level);
            setFoundAndStakedCoinsVisible(true);
          } else if (stakedAmount) {
            setStakedCoinsAmount(stakedAmount);
            setStakedCoinsLevel(level);
            setStakedCoinsVisible(true);
          } else if (digUpAmount) {
            setLastDigUpAmount(digUpAmount);
            setFoundTreasureVisible(true);
          } else if (!stakedAmount && !digUpAmount) {
            setStakeEmptyVisible(true);
          }
        }}
      />

      <YourStakesModal
        visible={yourStakesVisible}
        onClose={() => setYourStakesVisible(false)}
        onFinish={() => setYourStakesVisible(false)}
      />

      <StakeEmptyModal
        visible={stakeEmptyVisible}
        onClose={() => setStakeEmptyVisible(false)}
      />

      <StakedCoinsModal
        visible={stakedCoinsVisible}
        amount={stakedCoinsAmount}
        level={stakedCoinsLevel}
        onClose={() => setStakedCoinsVisible(false)}
      />

      <FoundAndStakedCoinsModal
        visible={foundAndStakedCoinsVisible}
        foundAmount={lastDigUpAmount}
        stakedAmount={stakedCoinsAmount}
        level={stakedCoinsLevel}
        onClose={() => setFoundAndStakedCoinsVisible(false)}
      />
    </section>
  );
});

DiggyGame.displayName = 'DiggyGame';
