import { ArrowRighticon } from '../../ui/ArrowRighticon';

import s from './TourStepIntro.module.css';

export const TourStepIntro = ({ onNext }) => {
  return (
    <section className={s.outer}>
      <div className={s.heading}>WELCOME</div>
      <div className={s.text}>
        Welcome to <span>diggy world!</span> <br /> We can't wait to get
        started, <br /> so <span>get your first brand</span> and let's go!
      </div>

      <div className={s.arrow} onClick={onNext}>
        <ArrowRighticon />
      </div>
    </section>
  );
};
