import { createContext, useContext, useMemo, useState } from 'react';

import { TourStepClaim } from '../../features/tour/steps/TourStepClaim';
import { TourStepIntro } from '../../features/tour/steps/TourStepIntro';
import { TourStepStakeChoosePlace } from '../../features/tour/steps/TourStepStakeChoosePlace';
import { TourStepStakeNavigate } from '../../features/tour/steps/TourStepStakeNavigate';

import { useAppProvider } from './app-context';

export const TourContext = createContext({});

export const TourProvider = ({ children }) => {
  const { profile } = useAppProvider();

  const [tourStep, setTourStep] = useState(profile?.tour_step ?? 0);
  const [tourFinished] = useState(profile?.tour_finished ?? true);

  const value = useMemo(() => {
    return { tourStep, tourFinished };
  }, [tourStep, tourFinished]);

  return (
    <TourContext.Provider value={value}>
      {children}

      {!tourFinished && (
        <>
          {tourStep === 0 && <TourStepIntro onNext={() => setTourStep(1)} />}

          {tourStep === 1 && <TourStepClaim onNext={() => setTourStep(2)} />}

          {tourStep === 2 && (
            <TourStepStakeNavigate onNext={() => setTourStep(3)} />
          )}

          {tourStep === 3 && <TourStepStakeChoosePlace />}
        </>
      )}
    </TourContext.Provider>
  );
};

export const useTourProvider = () => useContext(TourContext);
